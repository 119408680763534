<template>
  <v-card>
    <v-dialog v-model="ticket_dialog" width="80%" scrollable persistent>
      <gfo-order-ticket
        :order="current_order"
        @closeTicket="
          ticket_dialog = false;
          current_order = null;
        "
      />
    </v-dialog>

    <v-toolbar color="titleBar" dark flat dense>
      <span class="pr-2">{{ $t("Filter") }}: </span>
      <span v-if="active">{{ $t("active") }}</span>
      <span v-else>{{ $t("canceled") }}</span>
      <v-spacer></v-spacer>

      <v-tooltip v-if="active" top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click="active = false" :loading="loading" icon>
            <v-icon>mdi-toggle-switch-off</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("show canceled orders") }}</span>
      </v-tooltip>

      <v-tooltip top v-else>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click="active = true" :loading="loading" icon>
            <v-icon>mdi-toggle-switch</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("show active orders") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            :loading="loadingExcel"
            :disabled="loadingExcel"
            @click="downloadXlsx"
            icon
          >
            <v-icon>mdi-file-excel</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("download products Excel sheet") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-subtitle>
      <v-row>
        <v-col cols="3">
          <v-menu open-on-click :close-on-content-click="false" top offset-y>
            <template v-slot:activator="{ on }">
              <v-text-field v-on="on" readonly :value="rangePrint">
              </v-text-field>
            </template>
            <v-date-picker v-model="dates" range></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="9">
          <v-text-field
            v-model="search"
            append-outer-icon="mdi-magnify"
            :clearable="!isMobile"
            v-bind:label="$t('search')"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :options.sync="table_options"
        :items="rows"
        :server-items-length="server_items_length"
        :mobile-breakpoint="mbreakpoint"
      >
        <template v-slot:item.e_billing="{ item }">
          <boolean-value v-model="item.e_billing" colored />
        </template>

        <template v-slot:item.remarks="{ name, value }">
          <v-tooltip bottom v-if="value">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-account-tie-voice</v-icon>
              </v-btn>
            </template>
            <span>{{ value }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.ordered="{ name, value }">
          {{ formatDateTime(value) }}
        </template>

        <template v-slot:item.ise="{ name, value }">
          <span v-if="item.on_hold">
            {{ $t("on hold") }}
          </span>
          <span v-else>
            {{ value }}
          </span>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="item.ticket_id"
                @click="
                  current_order = item;
                  ticket_dialog = true;
                "
              >
                <v-list-item-title>{{ $t("show ticket") }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="
                  isSak &&
                    !item.canceled &&
                    item.positions.some((element) => element.product_id === 30)
                "
                @click="resendDaegoIdentification(item)"
              >
                <v-list-item-title>{{ $t("resend daego") }}</v-list-item-title>
              </v-list-item>

            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
var moment = require("moment");
import downloadFile from "@/utils/mixins/downloadFile";
import formatDateTime from "@/utils/mixins/formatDateTime";
import BooleanValue from "@/components/basics/BooleanValue";
import GfoOrderTicket from "@/components/orders/gfoOrder/GfoOrderTicket";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "GfoOrders",
  mixins: [downloadFile, formatDateTime, isMobile],
  components: {
    BooleanValue,
    GfoOrderTicket,
  },
  data: () => ({
    active: true,
    loading: false,
    loadingExcel: false,
    dates: [
      moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      moment()
        .endOf("month")
        .format("YYYY-MM-DD"),
    ],
    search: null,
    server_items_length: 0,
    table_options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ["ordered"],
      sortDesc: [false],
      multiSort: false,
    },
    current_order: null,
    ticket_dialog: false,
    rows: [],
  }),
  computed: {
    isSak: function() {
      var allowed = [];
      allowed.push(10000); // for testing
      allowed.push(307612);
      return -1 < allowed.indexOf(this.$store.state.session.number);
    },
    headers: function() {
      var headers = [
        {
          text: this.$i18n.t("order date"),
          value: "ordered",
          sortable: true,
        },
        {
          text: this.$i18n.t("ticket ID"),
          value: "ticket_id",
          sortable: false,
        },
        {
          text: this.$i18n.t("summary"),
          value: "summary",
          sortable: false,
        },
        {
          text: this.$i18n.t("eBill"),
          value: "e_billing",
          sortable: false,
        },
        {
          text: this.$i18n.t("remarks"),
          value: "remarks",
          sortable: false,
        },
      ];
      if (this.isSak) {
        headers.push({
          text: this.$i18n.t("ISE"),
          value: "external_customer_number",
          sortable: false,
        });
      }
      headers.push({
        text: this.$i18n.t("actions"),
        value: "actions",
        sortable: false,
      });

      return headers;
    },
    rangePrint: function() {
      let range = "";
      if (this.dates[0]) {
        range += moment(this.dates[0]).format("DD.MM.YYYY");
      }
      range += " - ";
      if (this.dates[1]) {
        range += moment(this.dates[1]).format("DD.MM.YYYY");
      }
      return range;
    },
  },
  methods: {
    downloadXlsx: function() {
      var that = this;
      this.loadingExcel = true;
      var params = {
        canceled: this.active ? "false" : "true",
      };
      if (this.dates[0]) params["ordered_after"] = this.dates[0];
      if (this.dates[1]) params["ordered_before"] = this.dates[1];
      try {
        this.downloadFile(
          "orders/gfo/xls",
          "get",
          params,
          "orders.xlsx",
          null,
          function() {
            that.loadingExcel = false;
          }
        );
      } catch (error) {}
    },
    loadData: function() {
      var that = this;
      this.loading = true;
      var path = "orders/gfo";
      var params = {
        search: this.search,
        page_size: this.table_options["itemsPerPage"],
        page_number: this.table_options["page"] - 1,
        order_by: this.table_options["sortBy"][0],
        order: this.table_options["sortDesc"][0] ? "desc" : "asc",
        canceled: this.active ? "false" : "true",
      };
      if (this.dates[0]) {
        params["ordered_after"] = this.dates[0];
      }
      if (this.dates[1]) {
        params["ordered_before"] = this.dates[1];
      }
      this.$http
        .get(path, { params: params })
        .then((response) => {
          that.rows = response.data.results;
          that.server_items_length = response.data.count;
        })
        .catch((err) => {
          if (err.invalid()) {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },

    resendDaegoIdentification: function(order) {
      var that = this;
      var path = "orders/gfo/" + order.reference + "/send_daego_app_link";
      this.$http
        .post(path, {})
        .then((response) => {
          // nothing to do
        })
        .catch((err) => {
          if (err.invalid()) {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          }
        })
        .finally(function() {
          that.loadData();
        });
    },
  },
  watch: {
    active() {
      this.loadData();
    },
    search() {
      this.loadData();
    },
    dates() {
      this.loadData();
    },
    table_options() {
      this.loadData();
    },
  },
  mounted: function() {
    this.loadData();
  },
};
</script>

<i18n>
{
  "en": {
    "show ticket": "Show ticket",
    "GFO orders": "GFO orders",
    "resend daego": "Resend Daego identification",
    "active orders": "Active orders",
    "canceled orders": "Canceled orders",
    "download products Excel sheet": "Download products Excel sheet",
    "show active orders": "Show active orders",
    "show canceled orders": "Show canceled orders",
    "on hold": "On hold",
    "actions": "Actions",
    "ISE": "ISE",
    "remarks": "Remarks",
    "ticket ID": "Ticket ID",
    "summary": "Summary",
    "order date": "Order date",
    "eBill": "eBill",
    "Filter": "Filter",
    "canceled": "canceled",
    "active": "active"
  },
  "de": {
    "show ticket": "Ticket anzeigen",
    "GFO orders": "GFO Bestellungen",
    "resend daego": "Daego Identification neu senden",
    "active orders": "aktive Bestellungen",
    "canceled orders": "anullierte Bestellungen",
    "download products Excel sheet": "Download Produkte Excel Sheet",
    "show active orders": "zeige aktive Bestellungen",
    "show canceled orders": "zeige annulliert Bestellungen",
    "on hold": "gestopt",
    "actions": "Aktionen",
    "ISE": "ISE",
    "remarks": "Anmerkung",
    "ticket ID": "Ticket ID",
    "summary": "Zusammenfassung",
    "order date": "Bestelldatum",
    "eBill": "eBill",
    "Filter": "Filter",
    "canceled": "annulliert",
    "active": "aktiv"
  }
}
</i18n>
