<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title v-if="!hideTitle">
        {{ $t("ticket") }}: {{ order != null ? order.summary : "" }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="loadTicket"
            :disabled="loading"
            :loading="loading"
            icon
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("refresh ticket") }}</span>
      </v-tooltip>
      <v-tooltip top v-if="!hideClose">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click="$emit('closeTicket')" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("close") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-progress-linear :active="loading" indeterminate />
    <v-card-text>
      <div v-html="content" class="ticket_styles" />
    </v-card-text>
    <v-card-actions v-if="!hideClose">
      <v-spacer></v-spacer>
      <v-btn @click="$emit('closeTicket')" text>
        {{ $t("close") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  name: "GfoOrderTicket",
  data: () => ({
    loading: null,
    content: "",
  }),
  methods: {
    loadTicket() {
      if (this.order != null) {
        var that = this;
        this.loading = true;
        this.content = "";
        this.$http
          .get("orders/gfo/" + this.order.reference + "/html", {
            headers: { accept: "text/html" },
          })
          .then((response) => {
            that.content = response.data;
          })
          .catch((error) => {
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
          })
          .finally(function() {
            that.loading = false;
          });
      }
    },
  },
  watch: {
    order() {
      this.loadTicket();
    },
  },
  mounted: function() {
    this.loadTicket();
  },
};
</script>

<i18n>
{
  "en": {
    "ticket": "Ticket",
    "close": "Close",
    "refresh ticket": "refresh ticket"

  },
  "de": {
    "ticket": "Ticket",
    "close": "Schliessen",
    "refresh ticket": "Ticket neu laden"
  }
}
</i18n>

<style lang="scss">
/**
 TODO: fix backend ticket template after frontend migration !!!
 */
.ticket_styles {
  ul {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .page-header small::before {
    content: "\2022";
    display: inline-block;
    padding-right: 10px;
  }

  .page-header small::after {
    content: "\A";
    white-space: pre;
  }

  .page-header small {
    font-size: 0.88rem;
    font-weight: bold;
  }

  .ticket-details div {
    padding: 20px;
    border-radius: 5px;
  }

  .theme--dark .ticket-details div {
    background: rgba(68, 66, 66, 0.5);
    box-shadow: 1px 1px 1px 1px rgba(54, 53, 53, 0.2);
  }

  .theme--dark .ticket-details {
    border-color: rgb(82, 79, 79);
    box-shadow: 1px 1px 1px 1px rgba(75, 74, 74, 0.2);
  }

  .theme--dark .ticket-details-info {
    border-left-color: #3c8dbc;
  }

  .theme--dark .ticket-details-danger {
    border-left-color: #d9534f;
  }

  .theme--light .ticket-details div {
    background: rgba(209, 209, 209, 0.5);
    box-shadow: 1px 1px 1px 1px rgba(221, 219, 219, 0.2);
  }

  .theme--light .ticket-details {
    border-color: rgb(224, 223, 223);
    box-shadow: 1px 1px 1px 1px rgba(221, 219, 219, 0.2);
  }

  .theme--light .ticket-details-info {
    border-left-color: #3c8dbc;
  }

  .theme--light .ticket-details-danger {
    border-left-color: #d9534f;
  }

  /**
 * from magic-customer-frontend-alex/css/core/tickets.css
 */

  .page-title {
    margin-bottom: 15px;
    font-size: 1.5rem;
  }

  .page-header {
    text-align: left;
  }

  .panel {
    border: none !important;
  }

  .panel-heading {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .row-panel {
    width: 100%;
    margin-left: 0;
  }

  .ticket-details {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-image: none;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px 1px 1px 5px;
    margin-bottom: 20px;
    padding: 20px;
  }

  .ticket-details:last-child {
    margin-bottom: 0;
  }

  .ticket-details h4 {
    margin-bottom: 10px;
    margin-top: 0;
  }

  .ticket-details-info h4 {
    color: #3c8dbc;
  }

  .ticket-details-danger h4 {
    color: #d9534f;
  }

  .ticket-details h2 {
    margin-top: 0;
  }

  .ticket-row {
    width: 100%;
  }

  .tickets .list-group-item:first-child,
  .tickets .list-group-item:last-child {
    border-radius: 0;
  }
}
</style>
